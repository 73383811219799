import { render, staticRenderFns } from "./ConfiguracionBD.vue?vue&type=template&id=794d3846&scoped=true"
import script from "./ConfiguracionBD.vue?vue&type=script&lang=js"
export * from "./ConfiguracionBD.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "794d3846",
  null
  
)

export default component.exports