import appsApi from "./admin.api";
import utils from "@/helpers/utilidades";

export default {
    /**
     *
     * @param aplicacion
     * @returns {Promise<AxiosResponse<any>>}
     */
    index(aplicacion) {
        return appsApi.api.get(utils.url_options(`${appsApi.baseURL}/aplicacion/${aplicacion}/clientes`));
    },

    crear(aplicacion,payload) {
        return appsApi.api.post(`${appsApi.baseURL}/aplicacion/${aplicacion}/clientes`, payload)
    },

    editar(aplicacion, cliente, payload) {
        return appsApi.api.put(`${appsApi.baseURL}/aplicacion/${aplicacion}/clientes/${cliente}`, payload)
    },

    eliminar(aplicacion, cliente) {
        return appsApi.api.delete(`${appsApi.baseURL}/aplicacion/${aplicacion}/clientes/${cliente}`)
    },

    /**
     * ROLES CLIENTE
     */
    index_roles(aplicacion,cliente) {
        return appsApi.api.get(utils.url_options(`${appsApi.baseURL}/aplicacion/${aplicacion}/clientes/${cliente}/roles`));
    },

    crear_rol(aplicacion,cliente,payload) {
        return appsApi.api.post(`${appsApi.baseURL}/aplicacion/${aplicacion}/clientes/${cliente}/roles`, payload)
    },

    rol_cliente(aplicacion, cliente, rol) {
        return appsApi.api.get(`${appsApi.baseURL}/aplicacion/${aplicacion}/clientes/${cliente}/roles/${rol}`)
    },

    editar_rol(aplicacion, cliente, rol, payload) {
        return appsApi.api.put(`${appsApi.baseURL}/aplicacion/${aplicacion}/clientes/${cliente}/roles/${rol}`, payload)
    },

    eliminar_rol(aplicacion, cliente, rol) {
        return appsApi.api.delete(`${appsApi.baseURL}/aplicacion/${aplicacion}/clientes/${cliente}/roles/${rol}`)
    },


    /**
     * PERMISOS ROLES CLIENTE
     */

    crear_permiso_rol(aplicacion,cliente,rol,payload) {
        return appsApi.api.post(`${appsApi.baseURL}/aplicacion/${aplicacion}/clientes/${cliente}/roles/${rol}/permisos`, payload)
    },

    editar_permiso_rol(aplicacion,cliente,rol,permiso,payload) {
        return appsApi.api.put(`${appsApi.baseURL}/aplicacion/${aplicacion}/clientes/${cliente}/roles/${rol}/permisos/${permiso}`, payload)
    },

    eliminar_permiso_rol(aplicacion,cliente,rol,permiso) {
        return appsApi.api.delete(`${appsApi.baseURL}/aplicacion/${aplicacion}/clientes/${cliente}/roles/${rol}/permisos/${permiso}`)
    },

    /**
     * USUARIOS CLIENTE
     */
    index_usuarios(aplicacion,cliente) {
        return appsApi.api.get(utils.url_options(`${appsApi.baseURL}/aplicacion/${aplicacion}/clientes/${cliente}/usuarios`));
    },

    crear_usuario(aplicacion,cliente,payload) {
        return appsApi.api.post(`${appsApi.baseURL}/aplicacion/${aplicacion}/clientes/${cliente}/usuarios`, payload)
    },

    usuario_cliente(aplicacion, cliente, usuario) {
        return appsApi.api.get(`${appsApi.baseURL}/aplicacion/${aplicacion}/clientes/${cliente}/usuarios/${usuario}`)
    },

    editar_usuario(aplicacion, cliente, usuario, payload) {
        return appsApi.api.put(`${appsApi.baseURL}/aplicacion/${aplicacion}/clientes/${cliente}/usuarios/${usuario}`, payload)
    },

    eliminar_usuario(aplicacion, cliente, usuario) {
        return appsApi.api.delete(`${appsApi.baseURL}/aplicacion/${aplicacion}/clientes/${cliente}/usuarios/${usuario}`)
    },

    reset_password(aplicacion, cliente, usuario) {
        return appsApi.api.get(`${appsApi.baseURL}/aplicacion/${aplicacion}/clientes/${cliente}/usuarios/${usuario}/reset-password`)
    },

    /**
     * CONFIGURACIÓN BASE DE DATOS
     */

    obtener_configuracion_bd(aplicacion,cliente) {
        return appsApi.api.get(`${appsApi.baseURL}/aplicacion/${aplicacion}/clientes/${cliente}/configuracion-bd`);
    },

    crear_configuracion_bd(aplicacion,cliente, payload) {
        return appsApi.api.post(`${appsApi.baseURL}/aplicacion/${aplicacion}/clientes/${cliente}/configuracion-bd`, payload);
    },

    editar_configuracion_bd(aplicacion,cliente, conexion, payload) {
        return appsApi.api.put(`${appsApi.baseURL}/aplicacion/${aplicacion}/clientes/${cliente}/configuracion-bd/${conexion}`, payload);
    },

    /**
     * APLICACIONES CLIENTES
     */

    listar_aplicaciones(aplicacion, cliente) {
        return appsApi.api.get(`${appsApi.baseURL}/aplicacion/${aplicacion}/clientes/${cliente}/aplicaciones?relations[0]=roles`);
    },

    crear_aplicacion(aplicacion, cliente, payload) {
        return appsApi.api.post(`${appsApi.baseURL}/aplicacion/${aplicacion}/clientes/${cliente}/aplicaciones`, payload)
    },

    editar_aplicacion(aplicacion, cliente, aplicacion_id, payload) {
        return appsApi.api.put(`${appsApi.baseURL}/aplicacion/${aplicacion}/clientes/${cliente}/aplicaciones/${aplicacion_id}`, payload)
    },
}
