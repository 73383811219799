<template>

  <div>

    <div class="text-center mb-5">
      <h5>Configuración de base de datos del cliente </h5>
      <span class="text-secondary d-block mb-3">Agregue la configuración de la base de datos del cliente aquí.</span>
    </div>


    <div class="body">

      <div class="row form-group">
        <label for="driver" class="col-form-label col-sm-2">Estatus conexión</label>
        <div class="col-sm-10">
          <select v-model="config.estatus" name="estatus" id="estatus" class="form-control">
            <option :value="1">Activa</option>
            <option :value="0">Inactiva</option>
          </select>
        </div>
      </div>

      <div class="row form-group">
        <label for="driver" class="col-form-label col-sm-2">Driver</label>
        <div class="col-sm-10">
          <select v-model="config.valor.driver" name="driver" id="driver" class="form-control">
            <option value="mysql">mysql</option>
          </select>
        </div>
      </div>

      <div class="row form-group">
        <label for="host" class="col-form-label col-sm-2">Host</label>
        <div class="col-sm-10"><input v-model="config.valor.host" type="text" name="host" id="host" class="form-control"></div>
      </div>

      <div class="row form-group">
        <label for="port" class="col-form-label col-sm-2">Puerto</label>
        <div class="col-sm-10"><input v-model="config.valor.port" type="text" name="port" id="port" class="form-control" maxlength="4"></div>
      </div>

      <div class="row form-group">
        <label for="database" class="col-form-label col-sm-2">Nombre base de datos</label>
        <div class="col-sm-10"><input v-model="config.valor.database" type="text" name="database" id="database" class="form-control"></div>
      </div>

      <div class="row form-group">
        <label for="user" class="col-form-label col-sm-2">Usuario</label>
        <div class="col-sm-10"><input v-model="config.valor.username" type="text" name="username" id="username" class="form-control"></div>
      </div>

      <div class="row form-group">
        <label for="password" class="col-form-label col-sm-2">Contraseña</label>
        <div class="col-sm-10"><input v-model="config.valor.password" type="password" name="password" id="password" class="form-control"></div>
      </div>

    </div>

  </div>

</template>

<script>

import Modal from '@/components/Modal'
import apiClientes from '@/apps/bpb_apps_admin/api/clientes.api'

export default {
  name: "ConfiguracionBD",
  components: {Modal},
  data() {
    return {
      password: null,
      config: {
        id: null,
        estatus: null,
        valor: {
          driver: null,
          host: null,
          port: null,
          database: null,
          username: null,
          password: null
        }
      }
    }
  },
  props: {
    aplicacion_id: null
    , cliente_id: null
  },
  computed: {},
  mounted() {
    this.cargarConfiguracion()
  },
  methods: {
    async cargarConfiguracion() {
      let dataSource = await apiClientes.obtener_configuracion_bd(this.aplicacion_id, this.cliente_id)
      if (dataSource.data.id) {

        let password = dataSource.data.valor.password.length > 255 ? dataSource.data.valor.password.slice(0, 255) :
            dataSource.data.valor.password;

        dataSource.data.valor.password = password
        this.password = password
        this.config = dataSource.data;
      }

    }

    , async guardar() {

      let isValid = await this.$validator({
        driver: !this.config.valor.driver ? 'required' : '',
        host: !this.config.valor.host ? 'required' : '',
        port: !this.config.valor.port ? 'required' : 'max:4',
        database: !this.config.valor.database ? 'required' : '',
        username: !this.config.valor.username ? 'required' : 'max:255',
        password: !this.config.valor.password ? 'required' : 'max:255',
      })

      if (isValid) {

        if (!this.config.id) {
          let payload = {
            password_confirmation: this.config.valor.password,
            estatus: this.config.estatus,
            ...this.config.valor
          };
          await apiClientes.crear_configuracion_bd(this.aplicacion_id, this.cliente_id, payload)

        } else {
          let payload = this.password !== this.config.valor.password ? {password_confirmation: this.config.valor.password, ...this.config.valor} :
              (({password, ...data}) => data)(this.config.valor)
          await apiClientes.editar_configuracion_bd(this.aplicacion_id, this.cliente_id, this.config.id, payload).then(() => {
            this.$helper.showMessage('Editar configuración base de datos cliente', 'Configuración actualizada exitosamente.', 'success', 'alert')
          })
        }

        this.$emit('configuracion_guardada')
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
