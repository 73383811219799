import Vue from "vue";
import axios from "@/plugins/axiosV2";
/**
 * baseUrl de api de admin de aplicaciones
 * @type {string}
 */
const baseURL = process.env.VUE_APP_API_BPB_APPS_ADMIN;


/**
 * Interceptor
    return response;
 */

const http = axios(baseURL);

http.interceptors.response.use(response => {
  return response;
}, async (error) => {
  let response = error.response;

  if ( response.config.url.includes(baseURL)){
    let message = !response.data.message ? `Error inesperado: ${response.data.code}` : response.data.message;

    if (response.status === 400 && response.data.error) {
      message += '<br>'+Object.values(response.data.error).join("<br>")
    }

    Vue.prototype.$helper.showMessage('Error',message,'error','alert');
  }

  return Promise.reject(error);
})


export default {
  api: http,
  baseURL
}

